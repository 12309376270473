export default {
	properties: [
		{
			text: 'N°',
			key: 'num',
			type: 'text',
			not_show_on_form: true,
			filter_modal_position: 7,
			filter_type: 'number',
		},
		{
			text: 'Imagenes',
			key: 'images',
			type: 'images',
			use_to_show_in_search_modal: true,
		},
		{
			text: 'Codigo de barras',
			table_text: 'Cod Barras',
			key: 'bar_code',
			type: 'text',
			value: null,
			use_to_check_if_is_repeat: true,
			use_to_show_in_search_modal: true,
			// filter_modal_position: 1,
			use_bar_code_scanner: true,
		},
		{
			text: 'Codigo de Proveedor',
			table_text: 'Cod Prov',
			key: 'provider_code',
			type: 'text',
			use_to_check_if_is_repeat: true,
			use_to_show_in_search_modal: true,
			filter_modal_position: 8,
		},
		{
			text: 'nombre',
			key: 'name',
			type: 'text',
			// type_if: {
			// 	condition: 'bar_code',
			// 	operator: '=',
			// 	value: null,
			// 	then: 'search',
			// 	else: 'text',
			// },
			store: 'article',
			save_if_not_exist: false,
			set_model_on_click_or_prop_with_query_if_null: true,
			auto_select: false,
			clear_query: false,
			is_title: true,
			use_to_show_in_search_modal: true,
			table_width: 'lg',
			filter_modal_position: 2,
		},
		{
			text: 'costo',
			key: 'cost',
			type_to_update: 'number',
			type: 'text',
			is_price: true,
			use_to_update: true,
			filter_modal_position: 10,
			filter_type: 'number',
		},
		{
			text: 'costo en dolares',
			key: 'cost_in_dollars',
			type: 'checkbox',
			use_to_update: true,
			prop_info: {
				text: 'Valor: ',
				model_prop: 'provider.dolar',
			},
			not_show: true,
		},
		{
			// text: 'costo_mano_de_obra',
			key: 'costo_mano_de_obra',
			type_to_update: 'number',
			type: 'text',
			is_price: true,
			if_has_extencion: 'production',
			not_show: true,
		},
		{
			text: 'Costo Real', 
			key: 'costo_real',
			only_show: true,
			is_price: true,
			not_show: true, 
			if_has_extencion: 'article.costo_real',
		},
		{
			text: 'margen de ganancia',
			key: 'percentage_gain',
			type: 'text',
			type_to_update: 'number',
			use_to_update: true,
			not_show: true,
			filter_modal_position: 12,
			filter_type: 'number',
			keep_after_create: true,
		},
		{
			text: 'precio',
			key: 'price',
			type: 'text',
			type_to_update: 'number',
			is_price: true,
			use_to_update: true,
			filter_modal_position: 11,
			filter_type: 'number',
		},
		{
			text: 'Precio final',
			key: 'final_price',
			type: 'number',
			only_show: true,
			is_price: true,
			use_to_show_in_search_modal: true,
			filter_modal_position: 9,
		},
		{
			text: 'Precio final anterior',
			key: 'previus_final_price',
			type: 'number',
			only_show: true,
			is_price: true,
			use_to_show_in_search_modal: true,
			not_show: true,
		},
		{
			text: 'Precio final actualizado',
			key: 'final_price_updated_at',
			type: 'text',
			only_show: true,
			is_date: true,
			not_show: true,
		},
		{
			text: 'stock',
			key: 'stock',
			type: 'number',
			filter_modal_position: 13,
			use_to_show_in_search_modal: true,
		},
		{
			text: 'stock minimo',
			key: 'stock_min',
			type: 'number',
			not_show: true,
		},
		{
			text: 'Disponible en la tienda',
			key: 'online',
			type: 'checkbox',
			value: 1,
			not_show: true,
			keep_after_create: true,
		},
		{
			text: 'Disponible en Mercado Libre',
			key: 'mercado_libre',
			type: 'checkbox',
			value: 1,
			not_show: true,
			keep_after_create: true,
		},
		{
			text: 'Destacado',
			key: 'featured',
			type: 'checkbox',
			not_show: true,
		},
		{
			text: 'En oferta',
			key: 'in_offer',
			type: 'checkbox',
			not_show: true,
		},
		{
			text: 'proveedor',
			key: 'provider_id',
			type: 'search',
			use_to_show_in_search_modal: true,
			use_to_update: true,
			use_store_models: true,
			filter_modal_position: 3,
			keep_after_create: true,
		},
		// {
		// 	text: 'precio en dolar del proveedor',
		// 	key: 'provider_cost_in_dollars',
		// 	type: 'checkbox',
		// },
		{
			text: 'Aplicar margen de ganancia del proveedor',
			key: 'apply_provider_percentage_gain',
			type: 'checkbox',
			value: 1,
			v_if: ['provider.percentage_gain', '!=', null],
			v_if_from_models_store: true,
			// show_if: {
			// 	model_prop_name: 'provider',
			// 	model_prop: 'percentage_gain',
			// }
			not_show: true,
			keep_after_create: true,
		},
		{
			text: 'Lista de precios',
			key: 'provider_price_list_id',
			type: 'search',
			is_between: {
				store: 'provider',
				model_prop: 'provider_price_lists',
			},
			not_show: true,
			// keep_after_create: true,
		},
		{
			text: 'categoria',
			key: 'category_id',
			type: 'search',
			use_to_show_in_search_modal: true,
			use_store_models: true,
			use_to_update: true,
			not_show: true,
			filter_modal_position: 4,
			keep_after_create: true,
		},
		{
			text: 'sub categoria',
			key: 'sub_category_id',
			type: 'search',
			depends_on: 'category_id',
			use_to_show_in_search_modal: true,
			use_store_models: true,
			use_to_update: true,
			not_show: true,
			filter_modal_position: 5,
			keep_after_create: true,
		},
		{
			text: 'iva',
			key: 'iva_id', 
			type: 'select',
			use_store_models: true,
			use_to_show_in_search_modal: true,
			not_show: true,
			keep_after_create: true,
		},
		{
			text: 'Marca',
			key: 'brand_id',
			type: 'search',
			use_to_show_in_search_modal: true,
			use_store_models: true,
			not_show: true,
			not_show: true,
			filter_modal_position: 6,
			keep_after_create: true,
		},
		{
			text: 'Condicion',
			key: 'condition_id',
			type: 'select',
			not_show: true,
			not_show: true,
		},
		{
			text: 'Por defecto en VENDER',
			key: 'default_in_vender',
			type: 'checkbox',
			not_show: true,
			if_has_extencion: 'articles_default_in_vender',
		},
		{
			text: 'Stock por direccion',
			key: 'addresses',
			only_show: true,
			type: 'search',
			store: 'address',
			belongs_to_many: {
				model_name: 'address',
				props_to_show: [
					{
						text: 'Deposito',
						key: 'street',
					},
				],
				pivot_props_to_show: [
					{
						text: 'Cantidad',
						key: 'amount',
					},
				],
			},
			not_show: true,
		},
		{
			text: 'Movimientos de Stock',
			type: 'button',
			button: {
				button_text: 'Mover stock entre depositos',
				variant: 'primary',
			},
			modal: 'address-movement',
			v_if_prop_length: 'addresses',
			not_show: true,
		},
		{
			text: 'Comenzar a utilizar depositos',
			type: 'button',
			button: {
				button_text: 'Repartir stock global entre depositos',
				variant: 'primary',
			},
			modal: 'create-article-addresses',
			v_if_prop_not_length: 'addresses',
			not_show: true,
		},
		// {
		// 	text: 'Depositos',
		// 	key: 'deposits',
		// 	type: 'search',
		// 	store: 'deposit',
		// 	belongs_to_many: {
		// 		properties_to_set: [
		// 			{
		// 				text: 'Cantidad',
		// 				key: 'value',
		// 				type: 'number',
		// 			},
		// 		],
		// 	},
		// 	not_show: true,
		// },
		{
			text: 'Descuentos',
			key: 'article_discounts',
			has_many: {
				text: 'Descuento',
				model_name: 'article_discount',
			},
			not_show: true,
		},
		{
			text: 'Descripciones',
			key: 'descriptions',
			has_many: {
				text: 'Descripcion',
				model_name: 'description',
			},
			not_show: true,
		},
		{
			// text: 'Descripciones',
			key: 'article_properties',
			// has_many: {
			// 	text: 'Descripcion',
			// 	model_name: 'description',
			// },
			not_show: true,
			not_show_on_form: true,
		},
	],
	singular_model_name_spanish: 'Articulo',
	plural_model_name_spanish: 'Articulos',
	create_model_name_spanish: 'Nuevo',
	text_delete: 'el',
	full_reactivity: true,
	show_all_models_on_display: false,
}